.header {
    display: flex;
    align-items: center;

    &.active {
        .menu {
            transform: translateX(0);
        }

        .btn {
            &__item {
                &:nth-child(1) {
                    transition: all .2s linear;
                    transform-origin: left top;
                    transform: rotate(45deg) translateX(-8px);
                }

                &:nth-child(3) {
                    opacity: 0;
                }

                &:nth-child(2) {
                    transition: all .2s linear;
                    transform-origin: left bottom;
                    transform: rotate(-45deg) translateX(-9px);
                }
            }
        }
    }
}

.title {
    font-size: 40px;
    font-weight: 800;
    margin-right: 140px;
    letter-spacing: -2px;
    @media (max-width: 568px) {
        font-size: 30px;
    }
}

.menu {
    &__list {
        display: flex;
        align-items: center;
    }

    @media (max-width: 1020px) {
        position: fixed;
        top: 0;
        right: 0;
        background-color: #000;
        max-width: 375px;
        width: 100%;
        height: 100vh;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(100%);
        transition: all .3s ease;
        &__list {
            flex-direction: column;
        }
    }
}

.item {
    font-size: 17px;
    margin-right: 20px;
    color: #fff;
    font-weight: 700;
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
        color: rgba(255, 255, 255, 0.6);
    }

    &.active {
        color: rgba(255, 255, 255, 0.7);
    }

    @media (max-width: 1020px) {
        font-size: 30px;
        margin-bottom: 30px;
        text-align: center;
    }
    @media (max-width: 375px) {
        font-size: 15px;
    }
}

.btn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    z-index: 11;
    display: none;

    &__item {
        width: 30px;
        height: 3px;
        background-color: #fff;
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }

    }

    @media (max-width: 1020px) {
        display: block;
    }
}