.portfolio {
    padding: 40px 0;

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__body {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.project {
    width: 15%;
    min-width: 150px;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid rgba(186, 63, 63, .4);
    display: flex;
    flex-direction: column;
    background-color: #000;
    transition: all .3s ease;
    cursor: pointer;

    &.active {
        width: 50%;
        min-width: 100px;
    }

    &:hover {
        background-color: rgba(186, 63, 63, .2);
    }

    &__link {
        display: flex;
        flex-direction: column;
    }

    &__logo {
        margin-bottom: 10px;
        flex-grow: 1;

        &.formed {
            border-radius: 50%;
            overflow: hidden;
        }

        img {
            width: 100%;
        }
    }

    &__name {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
    }
    @media (max-width: 320px) {
        &__name {
            font-size: 15px;
        }
    }
}