.intro {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding: 20px 0;
    &__container {
        display: flex;
        align-items: center;
    }
    @media (max-width: 768px) {
        padding: 50px 0;
        &__container {
            flex-direction: column;
        }
    }
}

.image {
    flex: 1 1 50%;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle, transparent, #000 90%);
    }

    img {
        width: 100%;
    }
    @media (max-width: 1020px) {
        flex: 1 1 40%;
    }
}

.info {
    flex: 1 1 50%;

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text {
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        text-align: justify;
        margin: 20px 0 0 10px;
        color: rgba(255, 255, 255, .7);
    }
    @media (max-width: 1020px) {
        flex: 1 1 60%;
    }
}